<template>
	<el-pagination background @current-change="changePage" :hide-on-single-page="true" layout="prev, pager, next" :total="total" />
</template>

<script>
export default {
	name: "NewPagination",
	props: {
		total: {
			type: Number,
			required: true
		}
	},
	emits: ['pageChanged'],
	methods: {
		changePage(page) {
			this.$emit('pageChanged', page)
		}
	}
}
</script>

<style scoped>

</style>
