<template>
    <page-header
        name="Accounts"
        :button-name="loading ? 'Requesting a link' : 'Add'"
        :on-create-click="requestLink"
    />
    <data-table
        ref="dataTable"
        :data-source="dataSource"
        :header-mapping="headerMapping"
        :rpp="10"
        :select-at-row="selectAtRow"
    />
</template>

<script>
import PageHeader from "../components/PageHeader";
import DataTable from "../components/DataTable";
import request from "../request";
import {Snackbar} from "@varlet/ui";

export default {
    name: "Accounts",
    components: {
        PageHeader,
        DataTable
    },
    created() {
        if (this.$route.query.code) {
            this.createAccount()
        }
    },
    data() {
        return {
            dataSource: (page, rpp) => new Promise((resolve, reject) => {
                request.get(process.env.VUE_APP_BASE_URL + '/vcs/accounts')
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            }),
            headerMapping: {
                login: 'Login',
                name: 'Name'
            },
            loading: false
        }
    },
    methods: {
        selectAtRow(id) {
            this.$router.push({
                name: 'Repositories',
                params: {
                    id
                }
            })
        },
        requestLink() {

            this.loading = true

            request.post(process.env.VUE_APP_BASE_URL + '/vcs/create_token')
                .then(response => {
                    this.loading = false
                    window.open(response.redirect_url);
                })
                .catch(error => {
                    this.loading = false
                    console.log(error)
                })
        },
        createAccount() {
            let code = this.$route.query.code
            let state = this.$route.query.state
            this.$router.replace({
                'code': null,
                'state': null
            })
            request.post(process.env.VUE_APP_BASE_URL + '/vcs/add_account?code=' + code + '&state=' + state)
                .then(response => {
                    this.$refs.dataTable.pageChanged(1)
                    Snackbar['success']("Successfully added account")
                })
                .catch(error => {
                    if (error.response.status == 403) {
                        Snackbar['error']("The account is already connected")
                    } else {
                        Snackbar['error']("Failed to add account")
                    }
                })
        }
    }
}
</script>

<style scoped>

</style>