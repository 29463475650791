<template>
	<var-skeleton :rows="10" :loading="loading">
		<var-table>
			<thead>
			<tr class="fw-bolder text-muted">
				<th v-for="field in header">
					<template v-if="field instanceof Object">
						{{ field.label }}
					</template>
					<template v-else>
						{{ field }}
					</template>
				</th>
			</tr>
			</thead>
			<tbody v-if="!isEmpty">
				<tr @click="selectAtRow(item.id)" v-for="(item, index) in data">
					<td v-for="(field, key) in header">
						<template v-if="field instanceof Object">
							<var-chip v-if="field.chip" :type="field.type(calculateValue(key, item))">
								{{ calculateValue(key, item) }}
							</var-chip>
							<var-space v-else-if="field.array">
								<var-chip
									v-for="(element, i) in calculateValue(key, item)"
									:key="i"
									type="info"
									size="small"
								>
									{{ element }}
								</var-chip>
							</var-space>
						</template>
						<template v-else>
							{{ calculateValue(key, item) }}
						</template>
					</td>
				</tr>
			</tbody>
			<template v-if="isEmpty" #default>
				<var-card
					title="No data"
					subtitle="There are currently no rows to display."
					layout="column"
					class="text-center"
				/>
			</template>

			<template #footer>
				<div class="footer">
					<div v-if="actionButtonName" class="container">
						<div class="row">
							<div class="col-6">
								<var-space>
									<var-button
										type="primary"
										@click="$emit('onActionClick')"
										:disabled="actionButtonDisabled"
										:title="actionButtonDisabledText">
										{{ actionButtonName }}
									</var-button>

									<var-button
										v-if="secondaryButtonName"
										type="primary"
										@click="$emit('onSecondaryButtonClick')"
										:disabled="secondaryButtonDisabled"
										:title="secondaryButtonDisabledText">
										{{ secondaryButtonName }}
									</var-button>
								</var-space>
							</div>
							<div class="col-6">
								<span class="float-end">
								<var-pagination
									:simple="false"
									:current="page"
									:total="total"
									@change="pageChanged"
									:show-size-changer="false"
									:size="rpp"
								/>
							</span>
							</div>
						</div>
					</div>
					<var-pagination
						v-else
						:simple="false"
						:current="page"
						:total="total"
						@change="pageChanged"
						:show-size-changer="false"
					/>
				</div>
			</template>
		</var-table>
	</var-skeleton>
</template>

<script>
import Pagination from "./Pagination";
import NewPagination from "./Pagination";
export default {
	name: "DataTable",
	components: {NewPagination, Pagination},
	emits: ['onActionClick', 'onSecondaryButtonClick'],
	created() {
		this.loading = true

		this.dataSource(this.page, this.rpp ?? 10)
			.then(data => {
				this.loading = false

				if (this.paginate === false) {
					this.data = data
				} else {
					this.data = data.data
					if (data.meta) {
						this.total = data.meta.pagination.total
						this.links = data.meta.pagination.links
					} else {
						this.total = data.total
						this.links = data.links
					}
				}
				this.isEmpty = this.data?.length === 0
				this.createHeader()
			})
			.catch(error => {
				console.log(error)
			})
	},
	props: {
		dataSource: {
			type: Function,
			required: true
		},
		headerMapping: {
			type: Object,
			required: true
		},
		rpp: {
			type: Number,
			required: false,
			default: 10
		},
		selectAtRow: {
			type: Function,
			required: true
		},
		paginate: {
			type: Boolean,
			required: false,
			default: true
		},
		actionButtonName: {
			type: String,
			required: false
		},
		actionButtonDisabled: {
			type: Boolean,
			required: false,
			default: false
		},
		actionButtonDisabledText: {
			type: String,
			required: false,
			default: ''
		},
		secondaryButtonName: {
			type: String,
			required: false
		},
		secondaryButtonDisabled: {
			type: Boolean,
			required: false,
			default: false
		},
		secondaryButtonDisabledText: {
			type: String,
			required: false,
			default: ''
		},
	},
	data() {
		return {
			data: [],
			header: [],
			links: [],
			page: 1,
			total: 0,
			loading: true,
			isEmpty: false
		}
	},
	methods: {
		createHeader() {
			this.header = this.headerMapping
		},
		calculateValue(key, item) {
			let value = key.split('.').reduce((o,i) => o[i], item)

			if (!value) {
				return '-'
			}

			return value
		},
		pageChanged(page) {
			this.loading = true
			this.page = page
			this.dataSource(this.page, this.rpp ?? 10)
				.then(data => {
					this.loading = false

					this.data = data.data

					if (data.meta) {
						this.total = data.meta.pagination.total
						this.links = data.meta.pagination.links
					} else {
						this.total = data.total
						this.links = data.links
					}

					this.isEmpty = this.data?.length === 0

					this.createHeader()
				})
				.catch(error => {
					console.log(error)
				})
		}
	}
}
</script>

<style scoped>

th:nth-child(n+1) {
	text-align: center !important;
}

th:nth-child(1) {
	text-align: left !important;
}

th:last-child {
	text-align: right !important;
}

td:nth-child(n+1) {
	text-align: center !important;
}

td:nth-child(1) {
	text-align: left !important;
}

td:last-child {
	text-align: right !important;
}

.footer {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: 60px;
	padding: 0 16px;
}

.var-space {
	display: inline-flex !important;
}

tbody tr {
	cursor: pointer;
}

</style>